import React, { FC, useEffect, useState } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { ColDef, GridOptions, GridReadyEvent, GridSizeChangedEvent } from 'ag-grid-community';
import { observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useStyles } from './RoleFieldGrid.styles';
import { RolesModel } from '../../../Shared';
import { AuthStore, USER_GROUP } from '@wings-shared/security';
import { IClasses, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { ChildGridWrapper, ConfirmDialog } from '@wings-shared/layout';
import {
  CustomAgGridReact,
  AgGridActions,
  AgGridActionButton,
  AgGridChipViewStatus,
  AgGridFilterHeader,
  useGridState,
  useAgGrid,
} from '@wings-shared/custom-ag-grid';
import { ExpandCollapseButton } from '@wings-shared/form-controls';
import { LOGS_FILTERS } from '../../../Shared/Enums';

interface Props {
  classes?: IClasses;
  rolesField: RolesModel[];
  openRoleFieldDialog: (rolesField: RolesModel, viewMode: VIEW_MODE) => void;
  upsertRoleField: (rolesField: RolesModel) => void;
  deleteRoleField: (id: number) => void;
}

const RoleFieldGrid: FC<Props> = ({ ...props }) => {
  const [ columnApi, setColumnApi ] = useState(null);
  const [ gridApi, setGridApi ] = useState(null);
  const classes = useStyles();
  const gridState = useGridState();
  const agGrid = useAgGrid<LOGS_FILTERS, RolesModel>([], gridState);

  useEffect(() => {
    if (!columnApi) return;

    const getLongerString = (...strings): string => strings.reduce((a, b) => a.length >= b.length ? a : b);
    const longestContents = {
      name: '',
      displayName: '',
      permissions: '',
    };

    props.rolesField.forEach((role: RolesModel) => {
      longestContents.name = getLongerString(longestContents.name, role.name);
      longestContents.displayName = getLongerString(longestContents.displayName, role.displayName);
      // Additional space in the join() is to compensate for the width of the status labels
      longestContents.permissions = getLongerString(longestContents.permissions, role.permissions.join('_'.repeat(6)));
    });

    columnApi.setColumnWidth('name', getContentWidth(longestContents.name));
    columnApi.setColumnWidth('displayName', getContentWidth(longestContents.displayName));
    columnApi.setColumnWidth('permissions', getContentWidth(longestContents.permissions));

    gridApi.sizeColumnsToFit();
  }, [ props.rolesField, columnApi ]);

  const getContentWidth = (content: string): number => {
    const cellPadding = 34;
    const divElement = document.createElement('div');
    const spanElement = document.createElement('span');
    spanElement.innerText = content;

    Object.assign(spanElement.style, {
      fontSize: '14px!important',
      display: 'inline!important',
      whiteSpace: 'nowrap!important',
    });

    document.body.appendChild(divElement);
    divElement.appendChild(spanElement);
    const { width: contentWidth } = spanElement.getBoundingClientRect();
    divElement.remove();

    return  Number.isFinite(contentWidth + cellPadding) ? contentWidth + cellPadding : 0;
  }

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
  };

  const confirmRemoveRoleField = (roleId: number): void => {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this field?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => props.deleteRoleField(roleId)}
      />
    );
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Role Name',
      field: 'name',
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
    },
    {
      headerName: 'Description',
      field: 'description',
      cellStyle: {
        lineHeight: '21px',
        minHeight: '40px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      suppressSizeToFit: false,
    },
    {
      headerName: 'Status',
      field: 'enabled',
      cellRenderer: 'agGridChipViewStatus',
      cellRendererParams: {
        isPlainText: true,
        isServicesStatus: true,
      },
      width: 15,
      valueFormatter: ({ value }) => (Boolean(value) ? 'Enabled' : 'Disabled')
    },
    {
      headerName: 'Permissions',
      field: 'permissions',
      cellRenderer: 'agGridChipViewStatus',
      cellRendererParams: {
        isPlainText: true,
      },
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionButtonRenderer',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        onAction: node => {},
        isEditOrDelete: true,
        isDisabled: () => false,
        isHidden: node => !AuthStore.userHasAccess(USER_GROUP.USER_MANAGEMENT_ADMIN),
        onClick: (node, isEditable) => {
          if (isEditable) return props.openRoleFieldDialog(node.data, VIEW_MODE.EDIT);
          return confirmRemoveRoleField(node.data.id);
        },
      },
    },
  ];

  /* istanbul ignore next */
  const gridActionProps = (): object => {
    return {
      showDeleteButton: true,
      getDisabledState: () => gridState.hasError,
      getEditableState: () => false,
      onAction: gridActions,
    };
  };

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: this,
      columnDefs,
      isEditable: false,
      gridActionProps,
    });
    return {
      ...baseOptions,
      suppressClickEdit: true,
      suppressHorizontalScroll: true,
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        suppressSizeToFit: true,
        autoHeight: true,
        filter: true,
        menuTabs: [ 'filterMenuTab' ],
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        agGridChipViewStatus: AgGridChipViewStatus,
        actionButtonRenderer: AgGridActionButton,
        agColumnHeader: AgGridFilterHeader,
      },
      pagination: false,
      onGridReady(event: GridReadyEvent) {
        setGridApi(event.api);
        setColumnApi(event.columnApi);
      },
      onGridSizeChanged(event: GridSizeChangedEvent) {
        event.api.sizeColumnsToFit();
      },
    };
  };

  return (
    <div className={classes.container}>
      <ChildGridWrapper
        onAdd={() => props.openRoleFieldDialog(new RolesModel(), VIEW_MODE.NEW)}
        hasAddPermission={true}
        disabled={gridState.isProcessing}
        title="Add Role"
      >
        <CustomAgGridReact
          isRowEditing={gridState.isRowEditing}
          rowData={props.rolesField}
          gridOptions={gridOptions()}
        />
      </ChildGridWrapper>
    </div>
  );
};

export default observer(RoleFieldGrid);
