export enum SETTING_CATEGORIES {
  GENERAL = 1,
  PERMIT_RULES,
  PERMIT,
}

export enum SETTING_ID {
  ACCESS_LEVEL = 1,
  SOURCE_TYPE,
  RULE_CONDITIONAL_OPERATOR,
  RULE_ENTITY,
  BLANKET_VALIDITY,
  DOCUMENT,
  DIPLOMATIC_CHANNEL_REQUIRED,
  ELEMENTS,
  FAR_TYPE,
  FLIGHT_OPERATIONAL_CATEGORY,
  LEAD_TIME_TYPE,
  PERMIT_TYPE,
  PERMIT_APPLIED_TO,
  PERMIT_NUMBER_EXCEPTIONS,
  PERMIT_PREREQUISITES,
  PERMIT_REQUIREMENT_TYPE,
  PURPOSE_OF_FLIGHT,
  TIME_LEVEL_UOM,
  MISSION_ELEMENT,
  DATA_ELEMENT,
  CROSSING_TYPE,
  PERMIT_CLASSIFICATION,
  PRESET_VALIDITY
}
