export const fields = {
  isCountryDataManager: {
    label: 'Country data management',
  },
  isPermitDataManager: {
    label: 'Permit data management',
  },
  isAirportDataManager: {
    label: 'Airport data management',
  },
  airport: {
    label: 'Airport*',
    value: [],
    rules: 'required',
  }
};
  